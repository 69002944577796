// Vue.js
if(window.Vue === undefined)
{
    window.Vue = require('vue');
}

import AddOffer from './Components/AddOffer';

const app = new Vue({
    el: '#add-offer',
    components: {
        AddOffer
    }
});

$(function() {

    'use strict';

    // SHOW/HIDE MOBILE MENU //
    function show_hide_mobile_menu() {

        $('.mobile-menu-btn').on('click', function(e) {

            e.preventDefault();
            e.stopPropagation();

            $('#mobile-menu').toggleClass('open');
            $('body').toggleClass('body-overlay');

        });

        $('body').on('click', function() {
            if ($('#mobile-menu').hasClass('open')) {
                $('#mobile-menu').removeClass('open');
                $('body').removeClass('body-overlay');
            }
        });

    }

    // MOBILE MENU //
    function mobile_menu() {

        if ($(window).width() < 1200) {

            if ($('#menu').length > 0) {

                if ($('#mobile-menu').length < 1) {

                    $('#menu').clone().attr({
                        id: 'mobile-menu',
                        class: ''
                    }).insertAfter('#header');

                    $('#mobile-menu li').each(function() {

                        if ($(this).hasClass('dropdown')) {
                            $(this).append('<span class="arrow"></span>');
                        }

                    });

                    $('#mobile-menu .dropdown .arrow').on('click', function(e) {

                        e.preventDefault();
                        e.stopPropagation();

                        $(this).toggleClass('open').prev('ul').stop().slideToggle(300);

                    });

                }

            }

        } else {

            $('#mobile-menu').removeClass('open');
            $('body').removeClass('body-overlay');

        }

    }

    function sticky() {

        let sticky_point = $('#header').innerHeight() + 100;

        $('#header').clone().attr({
            id: 'header-sticky',
            class: ''
        }).insertAfter('header');

        $(window).scroll(function() {

            if ($(window).scrollTop() > sticky_point) {
                $('#header-sticky').slideDown(300).addClass('header-sticky');
                $('#header .menu ul').css({'visibility': 'hidden'});
            } else {
                $('#header-sticky').slideUp(100).removeClass('header-sticky');
                $('#header .menu ul').css({'visibility': 'visible'});
            }

        });

    }

    // SHOW/HIDE SCROLL UP //
    function show_hide_scroll_top() {

        if ($(window).scrollTop() > $(window).height() / 2) {
            $('#scroll-up').fadeIn(300);
        } else {
            $('#scroll-up').fadeOut(300);
        }

    }

    // SCROLL UP //
    function scroll_up() {

        $('#scroll-up').on('click', function() {
            $('html, body').animate({
                scrollTop: 0
            }, 800);
            return false;
        });

    }

    // SHOW FILTERS //
    function show_filters() {
        $('.show-filters').on('click', function(e) {
            e.preventDefault();
            $(this).html($(this).html() == 'Ascunde filtre' ? 'Afișează filtre' : 'Ascunde filtre').next('ul').stop().slideToggle(300);
        });
    }

    // MODAL //
    function modal() {

        $('[data-toggle="modal"]').on('click', function(e) {

            e.preventDefault();

            let target = $(this).data('target');

            $('body').addClass('modal-open').append('<div class="modal-backdrop fade show"></div>').find(target).fadeIn(300).addClass('show');

            $('[data-dismiss="modal"]').on('click', function(e) {
                e.preventDefault();
                $('body').removeClass('modal-open').find(target).fadeOut(300).removeClass('show');
                $('.modal-backdrop').remove();
            });

        });
    }

    // MOBILE COOKIES //
    function mobile_cookies() {

        $('.cookie-popup h2').on('click', function() {
            $(this).parent().toggleClass('maximized');
        });

    }

    $(document).ready(function() {

        // STICKY //
        sticky();

        // MENU //
        $('.menu').superfish({
            popUpSelector: 'ul,.megamenu-container',
            delay: 500,
            animation: {
                opacity: 'show',
                height: 'show'
            },
            speed: 'fast',
            autoArrows: true
        });

        // MAIN SLIDER //
        let mainSlider = $('.main-slider .owl-carousel');

        mainSlider.on('initialized.owl.carousel', function() {
            setTimeout(function() {
                $('.main-slider .owl-carousel .slide-details').removeClass('animated');
            }, 300);
        });

        mainSlider.owlCarousel({
            items: 1,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            smartSpeed: 1500,
            loop: true,
            nav: true,
            navText: false,
            dots: true,
            mouseDrag: false,
            touchDrag: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut'
        });

        mainSlider.on('translate.owl.carousel', function() {
            $('.main-slider .owl-carousel .slide-details').delay(300).addClass('animated');
        });

        mainSlider.on('translated.owl.carousel', function() {
            $('.main-slider .owl-carousel .slide-details').removeClass('animated');
        });

        // PROPERTY GALLERY //
        $('.property-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            infinite: false,
            arrows: true,
            prevArrow: '<span class="slick-prev icon-arrow-left"></span>',
            nextArrow: '<span class="slick-next icon-arrow-right"></span>',
            fade: true,
        });

        let slidesToShow;

        if ($('.property-thumbs div ').length > 7) {
            slidesToShow = 8;
        } else {
            slidesToShow = $('.property-thumbs div ').length;
        }

        $('.property-thumbs')
            .on('init', function() {
                $('.property-thumbs .slick-slide.slick-current').addClass('is-active');
            }).slick({
            vertical: true,
            verticalSwiping: true,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            autoplay: false,
            infinite: false,
            arrows: true,
            prevArrow: '<span class="slick-prev icon-arrow-up"></span>',
            nextArrow: '<span class="slick-next icon-arrow-down"></span>',
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        prevArrow: '<span class="slick-prev icon-arrow-left"></span>',
                        nextArrow: '<span class="slick-next icon-arrow-right"></span>',
                        vertical: false,
                        verticalSwiping: false,
                        slidesToShow: slidesToShow
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        prevArrow: '<span class="slick-prev icon-arrow-left"></span>',
                        nextArrow: '<span class="slick-next icon-arrow-right"></span>',
                        vertical: false,
                        verticalSwiping: false,
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        prevArrow: '<span class="slick-prev icon-arrow-left"></span>',
                        nextArrow: '<span class="slick-next icon-arrow-right"></span>',
                        vertical: false,
                        verticalSwiping: false,
                        slidesToShow: 2
                    }
                }
            ]
        });

        $('.property-slider').on('afterChange', function(event, slick, currentSlide) {
            $('.property-thumbs').slick('slickGoTo', currentSlide);
            let currrentNavSlideElem = '.property-thumbs .slick-slide[data-slick-index="' + currentSlide + '"]';
            $('.property-thumbs .slick-slide.is-active').removeClass('is-active');
            $(currrentNavSlideElem).addClass('is-active');
        });

        $('.property-thumbs').on('click', '.slick-slide', function(event) {
            event.preventDefault();
            let goToSingleSlide = $(this).data('slick-index');
            $('.property-slider').slick('slickGoTo', goToSingleSlide);
        });

        // SHOW/HIDE MOBILE MENU //
        show_hide_mobile_menu();

        // MOBILE MENU //
        mobile_menu();

        // SHOW/HIDE SCROLL UP
        show_hide_scroll_top();

        // SCROLL UP //
        scroll_up();

        // SHOW FILTERS //
        show_filters();

        // MODAL //
        modal();

        // MOBILE COOKIES //
        mobile_cookies();

    });

    // WINDOW SCROLL //
    $(window).scroll(function() {

        show_hide_scroll_top();

    });

    // WINDOW RESIZE //
    $(window).resize(function() {

        mobile_menu();

    });

    $('.share').on('click', function(e) {
        e.preventDefault();
        let url = $(this).attr('href');
        let left = (screen.width / 2) - (335);
        let top = (screen.height / 2) - (200);
        window.open(url, 'share', `width=530, height=400, top=${top}, left=${left}`);
    });
});
