<template>
    <div class="add-offer-form">
        <div class="modal-header pb-0">
            <div>
                <h5 class="modal-title mb-2">Adaugă ofertă</h5>
                <p class="mb-0">Ai o proprietate de vânzare sau de închiriat? Specialiștii noștri sunt gata să te ajute. <br>
                    Completează formularul următor și în cel mai scurt timp te vom contacta pentru detalii.</p>
            </div>
            <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div><!-- modal-header -->
        <div class="modal-body">

            <div class="row" v-if="showMessage">
                <div class="col">
                    <div class="alert alert-success">
                        Oferta a fost trimisă, mulțumim. Te vom contacta în cel mai scurt timp
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg">
                    <div class="form-group mb-4">
                        <label>Tip tranzacție</label>
                        <select v-model="offer.transactionType"
                                class="custom-select"
                                placeholder="Alege tip tranzacție">
                            <option :value="transactionType.name" v-for="transactionType in transactionTypes">
                                {{ transactionType.name }}
                            </option>
                        </select>
                    </div><!-- form-group -->
                </div><!-- col -->
                <div class="col-lg">
                    <div class="form-group mb-4">
                        <label>Tip proprietate</label>
                        <select v-model="offer.propertyType"
                                class="custom-select"
                                placeholder="Alege tip proprietate">
                            <option :value="propertyType.name" v-for="propertyType in propertyTypes">
                                {{ propertyType.name }}
                            </option>
                        </select>
                    </div><!-- form-group -->
                </div><!-- col -->
            </div><!-- row -->

            <div class="row">
                <div class="col-lg">
                    <div class="form-group mb-4">
                        <label class="w-100">Nume <small class="text-danger float-right" v-if="errors.name" v-text="errors.name[0]"></small></label>
                        <input type="text"
                               v-model="offer.name"
                               name="name"
                               placeholder="Nume">
                    </div><!-- form-group -->
                </div><!-- col -->
                <div class="col-lg">
                    <div class="form-group mb-4">
                        <label>Email</label>
                        <input type="email"
                               v-model="offer.email"
                               name="email"
                               placeholder="Adresă e-mail">
                        <small class="text-danger" v-if="errors.email" v-text="errors.email[0]"></small>
                    </div><!-- form-group -->
                </div><!-- col -->
                <div class="col-lg">
                    <div class="form-grop mb-4">
                        <label class="w-100">Telefon<small class="text-danger float-right" v-if="errors.phone"
                                                           v-text="errors.phone[0]"></small></label>
                        <input type="text"
                               v-model="offer.phone"
                               name="phone"
                               placeholder="Telefon">
                    </div><!-- form-group -->
                </div><!-- col -->
            </div><!-- row -->

            <div class="row">
                <div class="col-lg">
                    <div class="form-grop mb-4">
                        <label class="w-100">Locație <small class="text-danger float-right" v-if="errors.location"
                                                            v-text="errors.location[0]"></small></label>
                        <input type="text"
                               v-model="offer.location"
                               name="location"
                               placeholder="Adresa completă a imobilului/terenului">
                    </div><!-- form-group -->
                </div><!-- col -->
            </div><!-- row -->

            <div class="row">
                <div class="col-lg">
                    <div class="form-group">
                        <label class="w-100">Scurtă descriere <small class="text-danger float-right" v-if="errors.message"
                                                          v-text="errors.message[0]"></small></label>
                        <textarea name="message"
                                  v-model="offer.message"
                                  cols="30"
                                  rows="5"
                                  placeholder="Scurtă descriere"></textarea>
                    </div><!-- form-group -->
                </div><!-- col -->
            </div><!-- row -->

        </div><!-- modal-body -->
        <div class="modal-footer">

            <button type="button" class="btn btn-secondary" data-dismiss="modal">Închide</button>
            <button type="submit"
                    :disabled="loading"
                    @click="saveOffer"
                    class="btn btn-primary">Trimite
            </button>

        </div><!-- modal-footer -->

    </div>
</template>

<script>
    import vSelect from 'vue-select';

    export default {
        components: {
            vSelect
        },

        data() {
            return {
                loading: false,
                showMessage: false,
                transactionTypes: [],
                propertyTypes: [],
                offer: {},
                errors: {},
            };
        },

        mounted() {
            this.getTransactionTypes();
            this.getPropertyTypes();
        },

        methods:
            {
                getTransactionTypes() {
                    axios.get('/transaction-types')
                        .then(data => {
                            this.transactionTypes = data.data.data;
                            this.offer.transactionType = this.transactionTypes[0].name;
                        })
                        .catch();
                },

                getPropertyTypes() {
                    axios.get('/property-types')
                        .then(data => {
                            this.propertyTypes = data.data.data;
                            this.offer.propertyType = this.propertyTypes[0].name;
                        })
                        .catch();
                },

                saveOffer() {
                    this.loading = true;
                    axios.post('/offer', this.offer)
                        .then(() => {
                            this.loading = false;
                            this.showMessage = true;
                            this.offer = {};
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errors = error.response.data.errors;
                        });
                },
            }
    };
</script>

<style scoped>

</style>
